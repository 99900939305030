<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container :title="$route.name" :exportInfo="currItems" :dates="[forms.begin, forms.end]">
      <template v-slot:bottom-header>
        <v-row class="mx-4">
          <v-col cols="12" md="3">
            <Date-Picker v-model="forms.begin" label="Fecha inicio" offYear offLimit />
          </v-col>
          <v-col cols="12" md="3">
            <Date-Picker v-model="forms.end" label="Fecha fin" offYear offLimit />
          </v-col>
          <v-col cols="12" md="1">
            <Basic-Btn text="Buscar" color="success" icon="mdi-magnify" @click="refresh()" :loading="loading" :disabled="!(forms.begin && forms.end)" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table :headers="headers" :items="items" :loading="loading" :search="search" @current-items="getFiltered"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="itemsPerPage" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.nombre`]="{ item }">
          {{ item.nombre + " " + item.apellido_paterno + " " + item.apellido_materno }}
        </template>
        <template v-slot:[`item.tiempo_retardo`]="{ item }">
          {{ item.tiempo_retardo ? item.tiempo_retardo : "" }}
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, BasicAlert, TableContainer, BasicBtn, DatePicker } from "@/components";
import moment from "moment";

export default {
  name: "events",
  components: {
    Loader,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DatePicker,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    dateSearch: null,
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    headers: [
      { text: "Numero de empleado", align: "left", sortable: true, value: "no_empleado" },
      { text: "Nombre", align: "left", sortable: true, value: "nombre" },
      { text: "", align: " d-none", sortable: true, value: "nombre_completo" },
      { text: "Evento", align: "left", sortable: true, value: "evento" },
      { text: "Departamento", align: "left", sortable: true, value: "departamento" },
      { text: "Fecha", align: "left", sortable: false, value: "fecha" },
      { text: "Retraso (En minutos)", align: "left", sortable: false, value: "tiempo_retardo" },
    ],
    items: [],
    users: [],
    currItems: [],
    itemsPerPage: 20,
    forms: {
      begin: "",
      end: "",
    },
  }),
  mounted() {
    this.getUser();
  },
  watch: {
    search(val) {
      this.itemsPerPage = (val) ? -1 : 20
    }
  },
  methods: {
    refresh() {
      this.loading = true;
      let request = "/calendar/full?fecha_inicio="+ this.forms.begin +"&fecha_fin="+ this.forms.end +"&eventos=" + this.$route.name.toUpperCase();
      if(this.$route.name == 'Incapacidad'){
       request = request + "&eventos=INCAPACIDAD POR MATERNIDAD&eventos=INCAPACIDAD POR RIESGO DE TRABAJO&eventos=INCAPACIDAD POR ENFERMEDAD GENERAL"
      }
      else if(this.$route.name == 'Faltas'){
       request = "/calendar/full?fecha_inicio="+ this.forms.begin +"&fecha_fin="+ this.forms.end + "&eventos=FALTA&eventos=FALTA INJUSTIFICADA&eventos=FALTA JUSTIFICADA&eventos=FALTA POR MOTIVOS PERSONALES"
      }
      else if(this.$route.name == 'Retardos'){
       request = "/calendar/full?fecha_inicio="+ this.forms.begin +"&fecha_fin="+ this.forms.end + "&eventos=RETARDO"
      }
      else if(this.$route.name == 'Omitio Entrada'){
       request = "/calendar/full?fecha_inicio="+ this.forms.begin +"&fecha_fin="+ this.forms.end + "&eventos=OMITIO ENTRADA"
      }
      this.axios
        .get(services.routes.event + request)
        .then((response) => {
          this.items = response.data.data;
          for (let i = 0; i < this.items.length; i++) {
            this.items[i].fecha = this.dateFormat(this.items[i].fecha + ' ' + this.items[i].hora)
            this.items[i].nombre_completo = this.items[i].nombre + " " + this.items[i].apellido_paterno + " " + this.items[i].apellido_materno
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Registros obtenidos correctamente.";
        });
    },
    async getUser() {
      this.loading = true;
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.users = response.data.data;
          for (let i = 0; i < this.users.length; i++) {
            this.users[i].nombreCompleto =
              (this.users[i].detalles_usuarios[0].no_empleado ? this.users[i].detalles_usuarios[0].no_empleado : "") +
              (this.users[i].detalles_usuarios[0].nombre ? " " + this.users[i].detalles_usuarios[0].nombre : "") +
              (this.users[i].detalles_usuarios[0].apellido_paterno ? " " + this.users[i].detalles_usuarios[0].apellido_paterno : "") +
              (this.users[i].detalles_usuarios[0].apellido_materno ? " " + this.users[i].detalles_usuarios[0].apellido_materno : "");
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY h:mm:ss A");
    },
    getFiltered(values) {
      this.currItems = values
    }
  },
};
</script>

<style scoped>
</style>
